import * as React from 'react';
import type { HeadFC, PageProps } from 'gatsby';
import Layout from '../layouts/Layout';

// @ts-ignore
import hero from '../images/hero.png';
// @ts-ignore
import logoIcon from '../images/logo-icon.svg';
// @ts-ignore
import image1 from '../images/image1.png';
// @ts-ignore
import image2 from '../images/image2.png';
// @ts-ignore
import lab from '../images/lab.png'
// @ts-ignore
import people from '../images/people2.png'

import { graphql, useStaticQuery } from 'gatsby';
import JobSearchForm from '../components/JobSearchForm/JobSearchForm';
import JobAlerts from '../components/JobAlerts/JobAlerts';

const IndexPage: React.FC<PageProps> = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	return (
		<Layout version={1} text={''} title={'Careers - StarPlus Energy - A Samsung SDI + Stellantis JV'} desc={'Careers - StarPlus Energy - A Samsung SDI + Stellantis JV'}>
			<div
				style={{ backgroundImage: `url('${hero}')` }}
				className={
					'flex h-[520px] md:h-[800px] w-full flex-col justify-end max-md:items-center bg-cover bg-top'
				}
			>
				<div className={'container mx-auto px-5'}>
					<div className={'md:w-1/2 w-full'}>
						<JobSearchForm data={data} />
					</div>
				</div>
			</div>
			<div className={'container mx-auto px-5 py-10'}>
				<div className={'flex flex-col items-center'}>
					{/*<img src={logoIcon} alt={'Logo'} />*/}
					<h1 className={'text-sl-blue font-encode text-center text-5xl font-thin max-w-4xl leading-[63px]'}>Changing the face of North American energy independence</h1>
					<p className={'font-encode text-xl text-center text-sl-body max-w-6xl mt-5'}>Powered by our purpose to lead the way the world moves, we are pushing the limits, expanding our vision, and embracing a new mindset.</p>
				</div>
			</div>
			<div className={'container mx-auto border-y-8 border-sl-blue'}>
				<div className={'flex md:flex-row flex-col'}>
					<div className={'flex flex-col md:w-1/2 justify-center max-lg:px-5 md:pr-10 max-md:py-10'}>
						<h2 className={'text-sl-blue text-3xl pb-6 font-encode font-300'}>Take on your next challenge</h2>
						<p className={'font-encode text-xl'}>Do you want to play a fundamental role in building the mobility of the future? Greatness starts with great people.</p>
						<a
							className="dn d-f-xl max-w-[200px] bg-sl-blue px-10 py-2 mt-6 text-center hover:text-white text-white"
							href={'about'}
							target={'_blank'}
						>
							<button>Working Here</button>
						</a>
					</div>
					<div className={'flex flex-col md:w-1/2 h-[330px] bg-cover bg-center'} style={{ backgroundImage: `url('${image1}')` }}/>
				</div>
				<div className={'flex md:flex-row flex-col-reverse'}>
					<div className={'flex flex-col md:w-1/2 h-[330px] bg-cover bg-center'} style={{ backgroundImage: `url('${image2}')` }}/>
					<div className={'flex flex-col md:w-1/2 justify-center md:pl-10 px-5 max-md:py-10'}>
						<h2 className={'text-sl-blue text-3xl pb-6 font-encode font-300'}>It all starts with human intelligence</h2>
						<p className={'font-encode text-xl'}>If we had all the answers, we wouldn’t be creating anything new. That’s where you come in. Together, we’ll roll up our sleeves and drive the future of mobility, electrification, and connectivity forward.</p>
					</div>
				</div>
			</div>
			<div style={{ backgroundImage: `url('${lab}')`}}  className={'mb-24 container bg-cover flex flex-row items-center bg-center  mx-auto h-[370px] w-full'} >
				<div className={'flex flex-col text-white font-encode sm:px-20 px-5'}>
					<h2 className={'text-3xl font-encode font-[100] pb-8'}>Where tech meets grit</h2>
					<p className={'text-xl font-encode max-w-md'}>We’re looking for people who will bring their passion and experience to work every day. Be part of our purpose.</p>
					<a className={'text-center font-encoded text-[22px] px-10 py-3 border w-52 mt-6'} href={'/search'}>Search Jobs</a>
				</div>
			</div>
			<JobAlerts />
			<div className={'h-[380px] bg-cover bg-center'} style={{ backgroundImage: `url('${people}')`}}/>
		</Layout>
	);
};

export default IndexPage;

