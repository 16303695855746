import * as React from 'react';
import { useState } from 'react';

// @ts-ignore
import closeIcon from './close.svg';

const JobAlerts: React.FC = () => {
	const [popupOpened, setPopupOpened] = useState(false);
	return (
		<section>
			<div
				className={
					'container mx-auto flex flex-col items-center px-5 pb-24 pt-2 text-center text-white'
				}
			>
				<h2 className={'font-encode text-3xl font-bold text-sl-blue'}>
					Sign up for Job Alerts
				</h2>
				<p className={'mt-6 max-w-xl font-encode text-lg text-black'}>
					Let’s keep in touch! Sign up for our talent community to
					receive job alerts and career news from StarPlus Energy LLC as a future
					employer.
				</p>
				<button
					className={
						'mt-8 w-44 rounded bg-sl-blue p-3 px-14 font-lato text-xl font-bold text-white hover:text-white hover:opacity-90 focus:opacity-90'
					}
					onClick={() => {
						setPopupOpened(true);
					}}
				>
					JOIN
				</button>
			</div>
			<div
				className={[
					'fixed top-0 bottom-0 right-0 left-0 bg-[rgba(0,0,0,0.40)]',
					!popupOpened ? 'hidden' : ''
				].join(' ')}
			>
				<div
					className={
						'flex h-full w-full flex-row items-center justify-center'
					}
				>
					<div
						className={'container flex items-center justify-center'}
					>
						<div
							className={
								'rounded bg-white px-12 shadow max-sm:px-5'
							}
						>
							<div className={'flex flex-row justify-between'}>
								<span
									className={
										' py-8 font-encode text-2xl font-bold text-sl-blue'
									}
								>
									JOIN THE TALENT COMMUNITY
								</span>
								<button
									onClick={() => {
										setPopupOpened(false);
									}}
								>
									<img
										width={26}
										className={'hover:opacity-70'}
										src={closeIcon}
										alt={'close popup window'}
									/>
								</button>
							</div>
							<iframe
								className={
									'max-sm:h-[45 0px] h-[500px] max-h-full w-[500px]	max-w-full'
								}
								style={{ border: 'none', maxHeight: '100%' }}
								src={
									'https://recruiting.adp.com/srctcb/public/portlet.guid?t=1761019&z=COLLECTOR&tt=VC'
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default JobAlerts;
